.nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    padding: 8px 0;

    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

  .nav-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;

    a {
      top: 35%;
      font-size: 22px;
      color: #4d4d4e;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;
      text-align: center;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #ffd700;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        width: 100%;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        left: 2px;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.blog-link {
      &:after {
        content: 'BLOG';
      }
    }

    a.active {
      svg {
        color: #ffd700;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 15px;
        line-height: 16px;
        color: #4d4d4e;
      }

      &:hover {
        .anchor-icon {
          color: #ffd700;
        }
      }
    }
  }
}

.hamburger-icon,
.close-icon {
  display: none;
}


@media screen and (max-width: 1200px) {
  .nav-bar {
    min-height: 0px;
    width: 100%;
    height: 60px;


    .nav-buttons {
      display: flex;
      flex-direction: row !important;
      justify-content: center;
      margin-top: 5px;

      a {
        top: 0%;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 40px;


        &:after {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .logo {
    float: left;
    margin-left: 15px;

    &:hover {
      transform: scale(125%) !important;
      transition: transform .15s ease-in-out;
    }
  }

  .sub-logo {
    display: none !important;
  }

  li {
    a {
      float: right;
      margin-right: 10px;
      width: 20px;
      padding: 2px 0px !important;
    }

    &:hover {
      .anchor-icon {
        color: #ffd700;
      }
    }
  }
}