.logo-container {
  display: flex;
  z-index: 0;
  width: 800px;
  height: auto;
  opacity: 10%;
  position: absolute;
  top: 0;
  right: 15%;

  .solid-logo {
    position: relative;
    right: auto;
    bottom: 10px;
    left: 10px;
    margin: auto;
    margin-left: 70px;
    margin-top: 100px;
    width: 100%;
    transform: rotateZ(30deg) !important;
    z-index: 1;
  }

}

@media screen and (max-width: 1200px) {
  .logo-container {
    position: relative;
    bottom: 0px;
    left: 20%;
    width: 40%;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 850px) {
    .logo-container {
      opacity: 0;
    }

  }
}