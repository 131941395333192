.about-page {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cubespinner {
    position: relative;
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    float: right;
    margin-right: calc(400px + 10%);
    bottom: 100px;

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }

    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }

    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }

    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }

    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }

    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
}

@keyframes spincube {

  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }

  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }

  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }

  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }

  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }

  83% {
    transform: rotateX(90deg);
  }
}

@media screen and (max-width: 1200px) {
  .text-zone {
    position: absolute !important;
    width: 60% !important;
    margin-top: 200px;
    top: 50px !important;
    margin-left: calc(50px + 10%);
  }

  .cubespinner {
    margin-left: 40%;
    top: 700px !important;
    float: left !important;
    margin-right: 0px !important;
  }

  @media screen and (max-height: 850px) {

    .cubespinner {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 850px) {
  .cubespinner {
    top: 600px !important;
  }
}