.home-page {

  .text-zone {
    justify-content: baseline;
    width: 70%;
    margin-top: 200px;
  }

  .h1-home {
    position: absolute;
    width: 120%;
    left: 200px;
    color: #fff;
    font-size: 56px;
    line-height: 53px;
    margin-right: 20px;
    font-family: 'Coolvetica';
    font-weight: 400;
    word-wrap: break-word !important;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      opacity: 0.6;
      left: -20px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }


    img {
      padding: 0;
      margin-left: -52px;
      margin-top: -10px;
      opacity: 0;
      width: 60px;
      height: auto;
      position: absolute;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    position: absolute;
    left: 200px;
    width: 100%;
    word-wrap: break-word;
    color: #8d8d8d;
    margin-top: 200px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.2s backwards;
  }

  .flat-button {

    position: absolute;
    left: 400px;
    top: 150px;

    color: #ffd700;
    width: 125px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 100px;
    animation: fadeIn 1s 1.2s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}



@media screen and (max-width: 1200px) {
  .text-zone {
    left: -180px;
    right: 150px;
  }

  .flat-button {
    top: 200px !important;
  }

  @media screen and (max-width: 850px) {
    .text-zone {
      top: 100px !important;
    }

    h2 {
      top: 50px;
    }

    .flat-button {
      left: 90% !important;
    }

    @media screen and (max-width: 728px) {
      .description {
        padding-top: 20px;
      }

      .flat-button {
        padding-top: 20px;
      }

      .flat-button {
        margin-top: 130px !important;
      }

      h1 {
        left: 175px !important;
      }
    }
  }
}