.text-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.contact-form {
  width: 100%;
  margin-top: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;

    &:hover {
      background-color: #115173;
      transition: background-color 100ms linear;
    }
  }
}

.map-wrap {
  background: rgba(8, 253, 216, 0.1);
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -65px;
}

.leaflet-container {
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: backInRight 1s 1.2s;
  animation-fill-mode: forwards;
}

.info-map {
  position: absolute;
  background: #000;
  top: 50px;
  right: 40px;
  z-index: 999999;
  width: 267px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s;
  animation-fill-mode: forwards;

  span {
    font-size: 16px;
    display: block;
    padding-top: 20px;
    color: #ffd700;

    span {
      color: #ffd700;
    }
  }
}

@media screen and (max-width: 1200px) {

  .map-wrap {
    margin-top: 760px;
    position: absolute !important;
    float: right !important;
    height: 400px;
    width: 70%;
    left: 15% !important;
  }


  .info-map {
    margin-top: 760px;
    position: relative !important;
    float: right;
    right: 16%;
    top: 12px;
    width: 220px;
    padding: 10px;
    font-size: 15px;
  }

  .flat-button {
    margin-top: -10px;
  }
}

@media screen and (max-width: 850px) {

  .map-wrap,
  .info-map {
    display: none;
  }

  li.half {
    width: 100% !important;
    margin-left: 0% !important;
    float: left;
    clear: both !important;
  }

  .text-zone {
    margin-top: 80px !important;
  }

  .flat-button {
    font-size: 15px !important;
    width: 100%;
    right: 50px;
  }

  .contact-me {
    font-size: 40px !important;
  }

}

@media screen and (max-height: 1100px) {

  .map-wrap,
  .info-map {
    display: none;
  }
}